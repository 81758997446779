import React, {useState, useEffect} from 'react';
import UserNatural from "../components/user-natural";
import UserEnterprise from "../components/user-enterprise";
import { switchCases } from '../utils/utils';
import {getCities} from "../modules/actions/actions";
import {Animated} from "react-animated-css";
import img from '../utils/images';

const Home = ({languageGlobalSelect}) => {

    const [stepSelect, setStepSelect] = useState('')
    const [cities, setCities] = useState({})
    const [showBottoms, setShowBottoms] = useState(true)

    const languageObject = {
        'button1': {
            es: 'Empresas',
            en: 'Business'
        },
        'button2': {
            es: 'Personas',
            en: 'Individual'
        }
    }

    const clickUserNatural =()=> {
        setStepSelect('person')
        setShowBottoms(false)
    }

    const clickUserEnterprise =()=> {
        setStepSelect('enterprise')
        setShowBottoms(false)
    }

    useEffect(()=> {
        getCities(setCities)
    }, [])

    const renderTypeUser =(step)=> {
        return switchCases(step, {
            'person': <UserNatural cities={cities?.results} languageGlobalSelect={languageGlobalSelect} setStepSelect={setStepSelect} setShowBottoms={setShowBottoms}/>,
            'enterprise': <UserEnterprise cities={cities?.results} languageGlobalSelect={languageGlobalSelect} setStepSelect={setStepSelect} setShowBottoms={setShowBottoms}/>
        })
    }


    return (
        <>

            {
                showBottoms &&
                <Animated
                    animationIn="fadeIn"
                    animationInDuration={1200}
                    className="animation"
                >
                    <div className="box-img none-550">
                        <img src={img.AnimationHome} alt="gomez pinzón" />
                    </div>

                    <div className="box-img animation-none active-550">
                        <img src={img.AnimationMobileHome} alt="gomez pinzón" />
                    </div>

                    <Animated
                        animationIn="fadeIn"
                        animationInDuration={5000}
                        className="content-text">
                        {
                            languageGlobalSelect === "Español" ?
                                <h1>Aquí podrás visualizar las fechas de las <strong>obligaciones legales</strong> más importantes para tu negocio.</h1>
                                :
                                <h1>Follow up import and legal <strong>obligations including tax,</strong> corporate and labour due dates.</h1>
                        }

                    </Animated>

                    <div className="box-buttons">
                        <Animated
                            className="content-info"
                            animationIn="fadeIn"
                            animationInDuration={5000}
                        >
                            {
                                languageGlobalSelect === 'Español' ?
                                    <h3>Selecciona el tipo de consulta que deseas realizar</h3>
                                    :
                                    <h3>Select type of query</h3>
                            }

                            <img className="icon" src={img.IconArrowGreyNext} alt="icon" />
                        </Animated>



                        <div className="content-right-buttons">
                            <button className="button black-button" onClick={clickUserEnterprise}>
                                {
                                    languageGlobalSelect === 'Español' ?
                                        languageObject.button1.es
                                        :
                                        languageObject.button1.en
                                }
                            </button>
                            <button className="button black-button" onClick={clickUserNatural}>
                                {
                                    languageGlobalSelect === 'Español' ?
                                        languageObject.button2.es
                                        :
                                        languageObject.button2.en
                                }
                            </button>
                        </div>
                    </div>
                </Animated>
            }

            {
                !showBottoms &&

                <div className="home">
                    <Animated
                        animationIn="fadeIn"
                        animationInDuration={1200}
                        className="img-background">
                        <img src={img.BackgroundHome} alt="background" />
                    </Animated>
                    {renderTypeUser(stepSelect)}
                    <div className="ending">
                        <div className="line"></div>
                        <h4>© Copyright 2020. Todos los derechos reservados. Gómez-Pinzón S.A.S.</h4>
                    </div>
                </div>
            }



        </>
    );
};


export default Home;


