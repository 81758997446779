import IconArrowWhiteBack from '../assets/images/icon-arrow-white-back.svg'
import IconArrowPurpleBack from '../assets/images/icon-arrow-purple-back.svg'
import IconArrowGreyNext from '../assets/images/icon-arrow-grey-next.svg'
import IconCloseModal from '../assets/images/icon-close-modal.svg'
import IconArrowSelect from '../assets/images/icon-arrow-select.svg'
import LogoGP from '../assets/images/logo-gp.svg'
import LogoSpanishHeader from '../assets/images/logo-spanish.svg'
import LogoEnglishHeader from '../assets/images/logo-english.svg'
import BackgroundHome from '../assets/images/background.png'
import AnimationHome from '../assets/images/animation.gif'
import AnimationMobileHome from '../assets/images/animation-mobile.gif'



export default {
    IconArrowWhiteBack,
    IconArrowPurpleBack,
    IconArrowGreyNext,
    IconCloseModal,
    IconArrowSelect,
    LogoGP,
    LogoSpanishHeader,
    LogoEnglishHeader,
    BackgroundHome,
    AnimationHome,
    AnimationMobileHome
}
