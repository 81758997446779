import React from 'react';
import img from '../utils/images';

const Spinner = () => {

    return (
        <>
            <div className="position-spinner">
                <div className="container-spinner">
                    <img src={img.LogoGP} alt="Gomez Pinzon"/>
                    <span className="loader"></span>
                </div>
            </div>

        </>
    );
};


export default Spinner;


