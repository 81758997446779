
export const optionGet = {
    method: 'GET',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ process.env.REACT_APP_AUT
    }
};

