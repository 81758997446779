import { optionGet } from './authorization'

export function getCities(cities) {
    fetch('https://no-olvidar.herokuapp.com/v1/utils/citys',
        optionGet
        )
        .then((response)=>response.json())
        .then((res)=>
            cities(res)
        )
        .catch((error)=>
            console.error("error", error)
        )
}


export function getAnnualIncome(value) {
    fetch('https://no-olvidar.herokuapp.com/v1/event/get-incomes',
        optionGet
    )
        .then((response)=>response.json())
        .then((res)=>
            value(res)
        )
        .catch((error)=>
            console.error("error", error)
        )
}


export function getEventService(filter, setEventsCalendar) {
    const url = `https://no-olvidar.herokuapp.com/v1/event/web/${filter?.type}/${filter?.nit}/${filter?.typeBusiness}/${filter?.incomeId}/${filter?.regime}/${filter?.city}/${filter?.nameCompany}/${filter?.email}/${filter?.industries}`
    fetch(url,
        optionGet
    )
        .then((response)=>response.json())
        .then((res)=>
            /*value(res)*/
            setEventsCalendar(res)
        )
        .catch((error)=>
            console.error("error", error)
        )
}