import React, {useState, useEffect} from 'react';
import img from '../utils/images';

const Header = ({setLanguageGlobalSelect}) => {

    const [languageSelect, setLanguageSelect] = useState('Español')
    const [openDrop, setIsOpenDrop] = useState(false)

    const openDropFunction =()=> {
        setIsOpenDrop(!openDrop)
    }


    const setSpanish =()=> {
        if(languageSelect === 'English') {
            setLanguageSelect('Español')
            setLanguageGlobalSelect('Español')
        }
    }

    const setEnglish =()=> {
        if(languageSelect === 'Español') {
            setLanguageSelect('English')
            setLanguageGlobalSelect('English')
        }
    }

    const reloadPage =()=> {
        window.location.reload(true)
    }


    return (
        <>
            <header className="header">
                <div className="return">
                    <a href="https://gomezpinzon.com/">
                        {
                            languageSelect === "Español" ?
                                <p>Ir a GPA</p>
                                :
                                <p>Go to GP</p>
                        }
                    </a>
                </div>
                <div className="logo" onClick={reloadPage}>
                    {languageSelect === "Español" ?
                        <img src={img.LogoSpanishHeader} alt="no olvidar gomez pinzón"/>
                        :
                        <img src={img.LogoEnglishHeader} alt="don't forget gomez pinzón"/>
                    }

                </div>
                <div className="section-right">
                    <div className="box-language">

                        <div className="button-language">
                            <p className={languageSelect === 'Español' ? 'item active-item' : 'item'} onClick={setSpanish}>Es</p>
                            <p>/</p>
                            <p className={languageSelect === 'English' ? 'item active-item' : 'item'} onClick={setEnglish}>En</p>
                        </div>

                        {openDrop && <div className="total-close" onClick={openDropFunction}/>}

                    </div>

                </div>
            </header>
        </>
    );
};


export default Header;


