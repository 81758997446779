import React, {useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'add-to-calendar-button/assets/css/atcb.css';

import Modal from "./modal";
import {languageObjectCompany} from "../utils/static-values";


const CalendarComponents = ({eventsCalendar, userName, languageGlobalSelect, typeSelected}) => {

    const localizer = momentLocalizer(moment)
    const [eventsBuilder, setEventsBuilder] = useState([])
    const [openModal, setIsOpenModal] = useState(false)
    const [eventSelected, setEventSelected] = useState({})
    const [messageCalendar, setMessageCalendar] = useState({})

    const handleSelectEvent=(e)=> {
        setEventSelected(e)
        setIsOpenModal(!openModal)
    }


    const buildNewEvents =(events)=> {
        let arrayEvents = []
        events?.events?.map((item)=>{
            let dateStart = new Date(item?.startDate);
            arrayEvents = [
                ...arrayEvents,
                {
                    ['start']: new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()),
                    ['end']: new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate()),
                    ['title']: languageGlobalSelect === 'Español' ? item?.title?.es : item?.title?.en,
                    ['colorEvent']: '#DADCDB',
                    ['color']: 'black',
                    ['description']: languageGlobalSelect === 'Español' ? item?.description?.es : item?.description?.en,
                    ['periodTitle']: languageGlobalSelect === 'Español' ? 'Periodo' : 'Period',
                    ['nameTitle']: languageGlobalSelect === 'Español' ? 'Nombre' : 'Name',
                    ['period']: languageGlobalSelect === 'Español' ? item?.subtitle?.es : item?.subtitle?.en,
                    ['name']: userName,
                    ['type']: languageGlobalSelect === 'Español' ? 'Empresa' : 'Company',
                    ['typePerson']: languageGlobalSelect === 'Español' ? 'Nombre' : 'Name',
                    ['nameDate']: languageGlobalSelect === 'Español' ? 'Vencimiento' : 'Expiration',
                    ['date']: item?.startDate,
                    ['buttonAdd']: languageGlobalSelect === 'Español' ? 'Agregar a mi calendario' : 'Add to my calendar',
                }
            ]
        })
        setEventsBuilder(arrayEvents)
    }


    useEffect(()=>{
        buildNewEvents(eventsCalendar)
    }, [eventsCalendar])

    useEffect(()=> {
        if (languageGlobalSelect === "Español") {
            require('moment/locale/es.js')
            setMessageCalendar({
                allDay: 'Dia Entero',
                previous: 'Atrás',
                next: 'Siguiente',
                today: 'Hoy',
                month: 'Mes',
                week: 'Semana',
                day: 'Dia',
                agenda: 'Agenda',
                date: 'Fecha',
                time: 'Hora',
                event: 'Evento',
                showMore: (total) => `+ (${total}) Eventos`,
            })
        } else {
            require('moment/locale/en-au.js')
            setMessageCalendar({
                allDay: 'All Day',
                previous: 'Back',
                next: 'Next',
                today: 'Today',
                month: 'Month',
                week: 'Week',
                day: 'Day',
                agenda: 'Schedule',
                date: 'Date',
                time: 'Hour',
                event: 'Event',
                showMore: (total) => `+ (${total}) Eventos`,
            })
        }

    }, [languageGlobalSelect])




    return (
        <section>
            <div className="calendar-components mt40">

                {
                    eventsBuilder && eventsBuilder.length > 0 &&
                    <Calendar
                        localizer={localizer}
                        events={eventsBuilder}
                        popup={true}
                        style={{ height: 600 }}
                        onSelectEvent={handleSelectEvent}
                        messages={messageCalendar}
                        eventPropGetter={(eventsBuilder) => {
                            const backgroundColor = eventsBuilder.colorEvent ? eventsBuilder.colorEvent : 'blue';
                            const color = eventsBuilder.color ? eventsBuilder.color : 'blue';
                            return { style: { backgroundColor, color} }
                        }}
                    />
                }

            </div>

            {
                openModal && Object.entries(eventSelected).length !== 0 &&
                (
                    <Modal
                        setIsOpenModal={setIsOpenModal}
                        eventSelected={eventSelected}
                        typeSelected={typeSelected}
                        languageGlobalSelect={languageGlobalSelect}
                    />
                )
            }


        </section>
    );
};


export default CalendarComponents;


