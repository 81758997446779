import React from 'react';
import {formatLongDate, formatShortDate, formatSimpleCalendar} from '../utils/validation'
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import img from '../utils/images';

const ModalInformer = ({setIsOpenModal, info}) => {


    const closeModal=()=> {
        setIsOpenModal(false)
    }


    return (
        <>
            <div className="body-modal">
                <div className="close-modal" onClick={closeModal} />
                <div className="box-modal">
                    <div className="close-inside-modal" onClick={closeModal}>
                        <img src={img.IconCloseModal}/>
                    </div>
                    <div className="content-info-modal">
                        <h2>{info && info}</h2>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ModalInformer;


