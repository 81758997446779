
export const validationFormEnterprise =(filter, languageGlobalSelect)=> {
    let error = ''

    if (filter?.nameCompany !== '' && filter?.nit !== '' && filter?.city !== '' && filter?.typeBusiness !== '' && filter?.incomeId !== '' && filter?.regime !== '' && filter?.type !== '' && filter?.email !== '') {
        error = ''
    } else {
        if (languageGlobalSelect === "Español") {
            error = 'Debes llenar todos los campos'
        } else {
            error = 'You must fill in all the fields'
        }
    }
    return error
}

export const validationEmail =(filter, languageGlobalSelect)=> {
    let error = ''

    if (filter?.email !== '') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(filter?.email)){
            if (languageGlobalSelect === "Español") {
                error = "Ingresa un correo electrónico válido";
            } else {
                error = 'Enter a valid email'
            }
        } else {
            error = ''
        }
    }
    return error
}

export const validationPoliticsData =(filter, languageGlobalSelect)=> {
    let error = ''

    if (filter?.politicsData !== true) {
        if (languageGlobalSelect === "Español") {
            error = 'Debes aceptar la política de tratamientos de datos'
        } else {
            error = 'You must accept the data processing policy'
        }

    } else {
        error = ''
    }
    return error
}

export const validationFormNaturalPerson =(filter, languageGlobalSelect)=> {
    let error = ''

    if (filter?.nameCompany !== '' && filter?.nit !== '' && filter?.city !== '') {
        error = ''
    } else {
        if (languageGlobalSelect === "Español") {
            error = 'Debes llenar todos los campos'
        } else {
            error = 'You must fill in all the fields'
        }
    }

    if (filter?.email !== '') {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(filter?.email)){
            if (languageGlobalSelect === "Español") {
                error = "Ingresa un correo electrónico válido";
            } else {
                error = 'Enter a valid email'
            }
        }
    }
    return error
}

export const validationFormNatural =(filter)=> {
    let error = ''
    if (filter?.name !== '' && filter?.cedula !== '' && filter?.city !== '' &&  filter?.type !== '') {
        error = ''
    } else {
        error = 'Debes llenar todos los campos'
    }
    return error
}

export const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
        );
    }
};

export const validateNumberInput = (evt) => {
    if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57){
        evt.preventDefault();
    }
}

export const formatLongDate = (date) => {
    const newDate = new Date(date);
    const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    const formatDate = newDate.toLocaleDateString("es-MX", options);
    return formatDate;
};

export const formatShortDate = (date) => {
    const newDate = new Date(date);
    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
    };
    const formatDate = newDate.toLocaleDateString("en-US", options);
    return formatDate;
};

export const formatSimpleCalendar = (date) => {
    let month = date.getMonth() + 1;
    let validateCurrentMonth = '';
    if (month.toString().length === 1) {
        validateCurrentMonth = '0' + month
    } else {
        validateCurrentMonth = month
    }
    let dateToday = date.getFullYear() + '-' + (validateCurrentMonth) + '-' + (date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate());
    return dateToday
}
