import './assets/styles/gpa-app-styles.scss'
import Routes from './routes/index';

function App() {

  return (
      <>
        <Routes/>
      </>
  );
}

export default App;
