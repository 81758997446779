
export const typeBusiness = [
    {
        'name_es': 'Grandes contribuyentes',
        'name_en': 'Big contributors',
        'value': 'contributors',
    },
    {
        'name_es': 'Micro empresa',
        'name_en': 'Micro company',
        'value': 'micro',
    },
    {
        'name_es': 'Pequeñas empresas',
        'name_en': 'Small business',
        'value': 'small',
    },
    {
        'name_es': 'Medianas empresas',
        'name_en': 'Medium business',
        'value': 'medium',
    },
    {
        'name_es': 'Empresas con más de un cierre contable al año',
        'name_en': 'Companies with more than one accounting closure per year',
        'value': 'companie_sclosure',
    },
]

export const typeRegime = [
    {
        'name_es': 'Régimen tributario especial',
        'name_en': 'Special tax regime',
        'value': 'special_tax',
    },
    {
        'name_es': 'Régimen común',
        'name_en': 'Common regime',
        'value': 'common_regime',
    },
    {
        'name_es': 'Régimen preferencial',
        'name_en': 'Preferential regime',
        'value': 'preferential_regime',
    },
]



export const industries = [
    {
        'name_es': 'Agroindustria',
        'name_en': 'Agribusiness',
        'value': 'Agroindustria',
    },
    {
        'name_es': 'Automotriz',
        'name_en': 'Automotive',
        'value': 'Automotriz',
    },
    {
        'name_es': 'Bancaria, mercado de capitales y servicios financieros',
        'name_en': 'Banking, capital markets and financial services',
        'value': 'Bancaria, mercado de capitales y servicios financieros',
    },
    {
        'name_es': 'Bienes de Consumo',
        'name_en': 'Consumer goods',
        'value': 'Bienes de Consumo',
    },
    {
        'name_es': 'Ciencias de la vida y cuidado de la salud',
        'name_en': 'Life sciences, healthcare',
        'value': 'Ciencias de la vida y cuidado de la salud'
    },
    {
        'name_es': 'Construcción e inmobiliario',
        'name_en': 'Real estate and construction',
        'value': 'Construcción e inmobiliario',
    },
    {
        'name_es': 'Educación',
        'name_en': 'Education',
        'value': 'Educación',
    },
    {
        'name_es': 'Energía y recursos naturales',
        'name_en': 'Energy and natural resources',
        'value': 'Energía y recursos naturales',
    },
    {
        'name_es': 'Entidades sin ánimo de lucro',
        'name_en': 'Non-profit entities',
        'value': 'Entidades sin ánimo de lucro',
    },
    {
        'name_es': 'Entretenimiento',
        'name_en': 'Entertainment',
        'value': 'Entretenimiento',
    },
    {
        'name_es': 'Hotelería y turismo',
        'name_en': 'Hospitality and turism',
        'value': 'Hotelería y turismo',
    },
    {
        'name_es': 'Infraestructura',
        'name_en': 'Infrastructure',
        'value': 'Infraestructura',
    },
    {
        'name_es': 'Manufactura',
        'name_en': 'Manufacturing',
        'value': 'Manufactura',
    },
    {
        'name_es': 'Retail',
        'name_en': 'Retail',
        'value': 'Retail',
    },
    {
        'name_es': 'Sector público',
        'name_en': 'Public sector',
        'value': 'Sector público',
    },
    {
        'name_es': 'Seguros y Reaseguros',
        'name_en': 'Insurance',
        'value': 'Seguros y Reaseguros',
    },
    {
        'name_es': 'Servicios Profesionales',
        'name_en': 'Professional services',
        'value': 'Servicios Profesionales',
    },
    {
        'name_es': 'Tecnología, medios y telecomunicaciones',
        'name_en': 'Technology, media and telecommunications',
        'value': 'Tecnología, medios y telecomunicaciones',
    },
    {
        'name_es': 'Transporte y logística',
        'name_en': 'Transport and logistics',
        'value': 'Transporte y logística',
    },
]


export const languageObjectCompany = {
    'name': {
        es: 'Nombre de la compañía',
        en: 'Company name'
    },
    'nit': {
        es: 'NIT (sin dígito de verificación)',
        en: 'Tax ID (first 9 digits)'
    },
    'email': {
        es: 'Correo electrónico',
        en: 'E-mail Address'
    },
    'city': {
        es: 'City',
        en: 'City'
    },
    'typeBusiness': {
        es: 'Tipo de empresa',
        en: 'Type of business'
    },
    'regime': {
        es: 'Régimen',
        en: 'Regime'
    },
    'incomeId': {
        es: 'Ingresos anuales',
        en: 'Annual income',
    },
    'industries': {
        es: 'Industria',
        en: 'Industry',
    },
    'button-consult': {
        es: 'Consultar',
        en: 'Search',
    },
    'new-consult': {
        es: 'Nueva Consulta',
        en: 'New search',
    },
    'back': {
        es: 'Empresas',
        en: 'Business',
    }
}

export const languageObjectNatural = {
    'name': {
        es: 'Nombre',
        en: 'Full Name'
    },
    'nit': {
        es: 'Cédula',
        en: 'ID last 2 digits'
    },
    'email': {
        es: 'Correo electrónico',
        en: 'E-mail address'
    },
    'city': {
        es: 'City',
        en: 'City'
    },
    'typeBusiness': {
        es: 'Tipo de empresa',
        en: 'Type Business'
    },
    'regime': {
        es: 'Régimen',
        en: 'Regime'
    },
    'incomeId': {
        es: 'Ingresos anuales',
        en: 'Annual income',
    },
    'industries': {
        es: 'Industria',
        en: 'Industries',
    },
    'button-consult': {
        es: 'Consultar',
        en: 'Search',
    },
    'new-consult': {
        es: 'Nueva Consulta',
        en: 'New search',
    },
    'back': {
        es: 'Persona natural',
        en: 'Individual',
    }
}

export const sortOrder = ['Barranquilla', 'Bogotá', 'Cali', 'Medellín', 'Pereira'];





