import React, {useState} from 'react';
import {BrowserRouter as Router,Route,Switch} from "react-router-dom";

import Home from '../pages/home';
import Header from '../components/header';
import { useHistory } from "react-router-dom";



const Routes = () => {

    const [languageGlobalSelect, setLanguageGlobalSelect] = useState('Español')

    return (
        <>
            <Header setLanguageGlobalSelect={setLanguageGlobalSelect}/>
            <Router history={useHistory}>
                <Switch>
                    <Route exact path="/" >
                        <Home languageGlobalSelect={languageGlobalSelect}/>
                    </Route>
                </Switch>
            </Router>
        </>
    );
};


export default Routes;
