import React from 'react';
import {formatLongDate, formatShortDate, formatSimpleCalendar} from '../utils/validation'
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import img from '../utils/images';

const Modal = ({setIsOpenModal, eventSelected, typeSelected, languageGlobalSelect, showButtons}) => {


    const closeModal=()=> {
        setIsOpenModal(false)
    }


    return (
        <>
            <div className="body-modal">
                <div className="close-modal" onClick={closeModal} />
                <div className="box-modal">
                    <div className="close-inside-modal" onClick={closeModal}>
                        <img src={img.IconCloseModal}/>
                    </div>
                    <div className="content-info-modal">
                        <h2>{eventSelected?.title}</h2>
                        <div className="grid-content">
                            <p><strong>{typeSelected === 'juridica' ? eventSelected?.type : eventSelected?.typePerson}</strong></p>
                            <p>{eventSelected?.name}</p>

                            {
                                eventSelected?.period !== '' && eventSelected?.period !== undefined && eventSelected?.period !== null ?
                                    <>
                                        <p><strong>{eventSelected?.periodTitle}</strong> </p>
                                        <p>{eventSelected?.period}</p>
                                    </>
                                    :
                                    undefined
                            }


                            <p><strong>{eventSelected?.nameDate}</strong></p>

                            {
                                languageGlobalSelect === "Español" ?
                                    <p>{formatLongDate(eventSelected?.date)}</p>
                                    :
                                    <p>{formatShortDate(eventSelected?.date)}</p>

                            }

                        </div>
                        <p>{eventSelected?.description}</p>
                    </div>


                    <form onSubmit={e => {
                        e.preventDefault();
                        atcb_action({
                            name: `No Olvidar GP - ${eventSelected?.title}`,
                            startDate: formatSimpleCalendar(new Date(eventSelected?.date)),
                            endDate: formatSimpleCalendar(new Date(eventSelected?.date)),
                            startTime: '08:00',
                            endTime: '08:30',
                            options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
                            timeZone: "America/Bogota",
                            iCalFileName: "Reminder-Event",
                            description: eventSelected?.description,
                        });
                    }}>

                        <div className="content-info-buttons">
                            <input className="button" type="submit" value={eventSelected?.buttonAdd} />
                            <div className="date-end">
                                <p>{formatShortDate(eventSelected?.date)}</p>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </>
    );
};


export default Modal;


