import React, {useState, useEffect} from 'react';
import { addPointNumber} from "../utils/utils";
import {typeBusiness, typeRegime, industries, languageObjectCompany, sortOrder} from "../utils/static-values";
import {getAnnualIncome, getEventService} from "../modules/actions/actions";
import {
    validationFormEnterprise,
    validateNumberInput,
    validationEmail,
    validationPoliticsData
} from "../utils/validation";
import CalendarComponents from "./calendar";
import Spinner from "./spinner";
import { Link } from "react-scroll";
import img from '../utils/images';
import ModalInformer from "./modal-informer";

const UserEnterprise = ({cities, languageGlobalSelect, setStepSelect, setShowBottoms}) => {

    const [filter, setFilter] = useState({
        'type': 'juridica',
        'nameCompany': '',
        'email': '',
        'nit': '',
        'city': '',
        'typeBusiness': '',
        'incomeId': '',
        'regime': '',
        'industries': '',
        'politicsData' : false,
    })

    const [annualIncome, setAnnualIncome] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessagePoliticsData, setErrorMessagePoliticsData] = useState('')
    const [eventsCalendar, setEventsCalendar] = useState({})
    const [loading, setLoading] = useState(false)
    const [openModal, setIsOpenModal] = useState(false)
    const [oneLoadModal, setOneLoadModal] = useState(false)


    const handleChange =(e)=> {
        const value = e?.target?.value
        const name = e?.target?.name
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleCheckbox =(e)=> {
        const value = e?.target?.checked
        setFilter({
            ...filter,
            'politicsData': value
        })
    }



    const search = async e => {
        const errorsValidation = validationFormEnterprise(filter, languageGlobalSelect)
        const errorsEmail = validationEmail(filter, languageGlobalSelect)
        const errorPoliticsData = validationPoliticsData(filter, languageGlobalSelect)

        setErrorMessage(errorsEmail !== '' ? errorsEmail : errorsValidation)
        setErrorMessagePoliticsData(errorPoliticsData)

        if (errorsValidation === '' && errorsEmail === '' && errorPoliticsData === '' ) {
            setEventsCalendar({})
            setLoading(true)
            try {
                await  getEventService(filter, setEventsCalendar)
                if (!oneLoadModal) {
                    setTimeout(()=>{
                        setIsOpenModal(true)
                        setOneLoadModal(true)
                    }, 600)
                }

            } catch (error) {
                console.error("error", error)
            }
            setTimeout(()=>{
                setLoading(false)
            }, 500)
        }
    }


    const getBack =()=> {
        setStepSelect('')
        setShowBottoms(true)
    }

    const newConsult=()=> {
        setStepSelect('')
        setShowBottoms(true)
        setEventsCalendar({})
        setLoading(false)
    }

    useEffect(()=> {
        getAnnualIncome(setAnnualIncome)
    }, [])

    return (
        <>
            <div className="user-enterprise">

                {
                    loading &&
                        <Spinner/>
                }

                <div className="return" onClick={getBack}>
                    <img src={img.IconArrowPurpleBack} alt="icon"/>
                    <p>{languageGlobalSelect === 'Español' ? languageObjectCompany?.back?.es : languageObjectCompany?.back?.en}</p>
                </div>

                <div className="content-form">
                    <input
                        type="text"
                        className="input-type"
                        placeholder={languageGlobalSelect === 'Español' ? languageObjectCompany?.name?.es : languageObjectCompany?.name?.en}
                        value={filter?.nameCompany}
                        name="nameCompany"
                        onChange={(e)=>handleChange(e)} />

                    <input
                        type="number"
                        name="nit"
                        value={filter?.nit}
                        className="input-type"
                        placeholder={languageGlobalSelect === 'Español' ? languageObjectCompany?.nit?.es : languageObjectCompany?.nit?.en}
                        onChange={(e)=>handleChange(e)}
                        onKeyPress={validateNumberInput}
                        required
                    />

                    <input
                        type="text"
                        name="email"
                        value={filter?.email}
                        placeholder={languageGlobalSelect === 'Español' ? languageObjectCompany?.email?.es : languageObjectCompany?.email?.en}
                        onChange={(e)=>handleChange(e)}
                        maxLength="50"
                        className="input-type"
                        required
                    />

                    <div className="arrow-select">
                        <select name="city" className="input-type arrow-select" onChange={(e)=>handleChange(e)}>
                            <>
                                <option value="" selected disabled hidden>{languageGlobalSelect === 'Español' ? 'Ciudad' : 'City'}</option>
                                {
                                    cities && cities?.sort((a, b)=>{
                                        var sa = sortOrder.indexOf(a?.name);
                                        var sb = sortOrder.indexOf(b?.name);
                                        if (sa == -1) return 1;
                                        if (sb == -1) return -1;
                                        return sa < sb ? -1 : 1;

                                    }).map((item, index)=>{
                                        return (
                                            <option key={index} value={item?.name}>{item?.name}</option>
                                        )
                                    })
                                }
                            </>
                        </select>
                        <img src={img.IconArrowSelect} alt="select" />
                    </div>


                    <div className="arrow-select">
                        <select name="typeBusiness" className="input-type arrow-select" onChange={(e)=>handleChange(e)}>
                            <>
                                <option value="" selected disabled hidden>{languageGlobalSelect === 'Español' ? languageObjectCompany?.typeBusiness?.es : languageObjectCompany?.typeBusiness?.en}</option>
                                {
                                    typeBusiness && typeBusiness.map((item, index)=>{
                                        return (
                                            <option key={index} value={item?.value}>{languageGlobalSelect === 'Español' ? item?.name_es : item?.name_en}</option>
                                        )
                                    })
                                }
                            </>
                        </select>
                        <img src={img.IconArrowSelect} alt="select" />
                    </div>


                    <div className="arrow-select">
                        <select name="regime" className="input-type arrow-select" onChange={(e)=>handleChange(e)}>
                            <>
                                <option value="" selected disabled hidden>{languageGlobalSelect === 'Español' ? languageObjectCompany?.regime?.es : languageObjectCompany?.regime?.en}</option>
                                {
                                    typeRegime && typeRegime.map((item, index)=>{
                                        return (
                                            <option key={index} value={item?.value}>{languageGlobalSelect === 'Español' ? item?.name_es : item?.name_en}</option>
                                        )
                                    })
                                }
                            </>
                        </select>
                        <img src={img.IconArrowSelect} alt="select" />
                    </div>


                    <div className="arrow-select">
                        <select name="incomeId" className="input-type arrow-select" onChange={(e)=>handleChange(e)}>
                            <>
                                <option value="" selected disabled hidden>{languageGlobalSelect === 'Español' ? languageObjectCompany?.incomeId?.es : languageObjectCompany?.incomeId?.en}</option>
                                {
                                    annualIncome && annualIncome.map((item, index)=>{
                                        return (
                                            <>
                                                <option key={index} value={item?.id}>
                                                    {
                                                        item?.range.map((itemInter, x)=>{
                                                            return (
                                                                <>
                                                                    {
                                                                        languageGlobalSelect === 'Español' ?
                                                                            <>
                                                                                {` ${itemInter?.placeholder?.es} ${addPointNumber(itemInter?.number)}`}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {` ${itemInter?.placeholder?.en} ${addPointNumber(itemInter?.number)}`}
                                                                            </>
                                                                    }

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </option>
                                            </>
                                        )
                                    })
                                }
                            </>
                        </select>
                        <img src={img.IconArrowSelect} alt="select" />
                    </div>

                    <div className="arrow-select">
                        <select name="industries" className="input-type arrow-select" onChange={(e)=>handleChange(e)}>
                            <>
                                <option value="" selected disabled hidden>{languageGlobalSelect === 'Español' ? languageObjectCompany?.industries?.es : languageObjectCompany?.industries?.en}</option>
                                {
                                    industries && industries.map((item, index)=>{
                                        return (
                                            <option key={index} value={item?.value}>{languageGlobalSelect === 'Español' ? item?.name_es : item?.name_en}</option>
                                        )
                                    })
                                }
                            </>
                        </select>
                        <img src={img.IconArrowSelect} alt="select" />
                    </div>



                    {
                        errorMessage !== '' &&
                        <div className="error-message">
                            <p>{errorMessage}</p>
                        </div>
                    }

                    <div className="checkbox">
                        <label><input type="checkbox"  value="politicsData" onChange={(e)=>handleCheckbox(e)} />
                            {languageGlobalSelect === "Español" ?
                                <>Acepto la politica de <a href="https://gomezpinzon.com/politica-tratamiento-datos/">datos personales</a></>
                                :
                                <>I accept the personal <a href="https://gomezpinzon.com/en/authorization-for-personal-data-processing/">data policy</a></>
                            }
                        </label>
                    </div>

                    {
                        errorMessagePoliticsData !== '' &&
                        <div className="error-message">
                            <p>{errorMessagePoliticsData}</p>
                        </div>
                    }



                    <div className="m-auto">

                        <Link
                            onClick={search}
                            to="calendar-ghost"
                            spy={true}
                            smooth={true}
                            offset={200}
                            duration={2000}
                        >
                            <button className="button black-button mt40 mb40">
                                {languageGlobalSelect === 'Español' ? languageObjectCompany?.["button-consult"]?.es : languageObjectCompany?.["button-consult"]?.en}
                            </button>

                        </Link>
                    </div>
                </div>

                {
                    Object.entries(eventsCalendar).length !== 0 &&
                    <CalendarComponents eventsCalendar={eventsCalendar} userName={filter.nameCompany} languageGlobalSelect={languageGlobalSelect}/>
                }

                <div className="calendar-ghost" id="calendar-ghost" />

                {
                    Object.entries(eventsCalendar).length !== 0 &&
                    <div className="align-new-consult">
                        <button className="button black-button mt40 mb40" onClick={newConsult}>
                            {languageGlobalSelect === 'Español' ? languageObjectCompany?.["new-consult"]?.es : languageObjectCompany?.["new-consult"]?.en}
                        </button>
                    </div>
                }


                {
                    openModal &&
                    <ModalInformer
                        setIsOpenModal={setIsOpenModal}
                        info={languageGlobalSelect === "Español" ? 'Recuerda que puedes hacer clic en cada evento del calendario' : 'Remember that you can click on each event on the calendar' }
                    />
                }



            </div>
        </>
    );
};


export default UserEnterprise;


